import { useState } from "react";
import {
  FAQs,
  MyVipLevel,
  ToNextLevel,
  TotalStaked,
  VipTiers,
  VipTiersMobile,
  OpenPoolsTable,
  ClosePoolsTable,
} from "./components";
import "./style.scss";
import { Switch } from "@mui/material";

export const Home = () => {
  const [isToggled, setIsToggled] = useState(false);

  return (
    <>
      {/* <TopPanel /> */}
      <div className="page-container container mx-auto py-6 gap-[60px] flex flex-col px-4">
        <div className="lg:grid hidden" id="StakeUnstacke">
          <VipTiers />
        </div>
        <div className="flex lg:hidden">
          <VipTiersMobile />
        </div>
        <div className="">
          <div className="mb-4 flex justify-between items-center">
            <span className="text-white font-bold text-2xl">STAKING DEFI</span>
            <div className="flex items-center">
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            <TotalStaked />
            <MyVipLevel />
            <ToNextLevel />
          </div>
          <div className="text-white font-bold text-2xl mb-4">ACTIVE POOLS</div>
          <OpenPoolsTable isMyPools={isToggled}/>
          <div className="text-white font-bold text-2xl mb-4 mt-8">
            CLOSED POOLS
          </div>
          <ClosePoolsTable isMyPools={isToggled} setIsToggled={setIsToggled} isToggled={isToggled}/>
        </div>
        <div className="">
          <FAQs />
        </div>
      </div>
    </>
  );
};
